import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    currentRouteKey: '',
    version:'',
    home: true
}

export const commonSlice = createSlice({
	name: 'base/common',
	initialState,
	reducers: {
        setCurrentRouteKey: (state, action) => {
            state.currentRouteKey = action.payload
        },
        setCurrentVersion: (state, action) => {
            state.version = action.payload
        },
        setHomeInactive: (state, action) => {
            state.home = action.payload
        }
	},
})

export const { setCurrentRouteKey , setCurrentVersion, setHomeInactive} = commonSlice.actions

export default commonSlice.reducer