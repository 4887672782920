import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    student_id: "",
    student_name: "",
    student_code: "",
    centre_id: "",
    centre_name: "",
    session_year: "",
    photofile_name: "",
    role_code: "",
    role_id: "",
    batch_id: "",
    course_id: ""
}

export const userSlice = createSlice({
    name: 'auth/user',
    initialState,
    reducers: {
        setUser: (_, action) => action.payload,
        userLoggedOut: () => initialState,
    },
})

export const { setUser } = userSlice.actions

export default userSlice.reducer