import React, { memo, useMemo, lazy, Suspense, useState, useEffect } from 'react'
import { Loading } from 'components/shared'
import { useDispatch, useSelector } from 'react-redux'
import {
	LAYOUT_TYPE_CLASSIC,
	LAYOUT_TYPE_MODERN,
	LAYOUT_TYPE_SIMPLE,
	LAYOUT_TYPE_STACKED_SIDE,
	LAYOUT_TYPE_DECKED,
	LAYOUT_TYPE_BLANK
} from 'constants/theme.constant'
import useAuth from 'utils/hooks/useAuth'
import useDirection from 'utils/hooks/useDirection'
import useLocale from 'utils/hooks/useLocale'
import packageJson from '../../../package.json'
import { Button, Dialog } from 'components/ui'
import { setCurrentVersion } from 'store/base/commonSlice'

const layouts = {
	[LAYOUT_TYPE_CLASSIC]: lazy(() => import('./ClassicLayout')),
	[LAYOUT_TYPE_MODERN]: lazy(() => import('./ModernLayout')),
	[LAYOUT_TYPE_STACKED_SIDE]: lazy(() => import('./StackedSideLayout')),
	[LAYOUT_TYPE_SIMPLE]: lazy(() => import('./SimpleLayout')),
	[LAYOUT_TYPE_DECKED]: lazy(() => import('./DeckedLayout')),
	[LAYOUT_TYPE_BLANK]: lazy(() => import('./BlankLayout')),
}

const Layout = () => {

	const layoutType = useSelector((state) => state.theme.layout.type)
	const currentVersion = useSelector((state) => state.base.common.version)
	const isHomeActive = useSelector((state) => state.base.common.home)

	const { authenticated } = useAuth()
	const dispatch = useDispatch()

	useDirection()
	useLocale()


	const [isCacheOld, setIsCacheOld] = useState(false);


	const AppLayout = useMemo(() => {
		if (authenticated && isHomeActive){
			return lazy(() => import('./HomeLayout'))
		}
		
		if (authenticated) {
			return layouts[layoutType]
		}
		return lazy(() => import('./AuthLayout'))
	}, [layoutType, authenticated, isHomeActive])


	let getBrowserCache = () => {

		if (currentVersion !== packageJson.version) {
			setIsCacheOld(true);
		}
	};

	let clearBrowserCache = () => {
		if ('caches' in window) {
			caches.keys().then((names) => {
				names.forEach(name => {
					caches.delete(name);
				})
			});
			localStorage.removeItem("brainware");
			dispatch(setCurrentVersion(packageJson.version))
			window.location.reload(true);
		}
		dispatch(setCurrentVersion(packageJson.version))
	}

	useEffect(() => {
		getBrowserCache();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Suspense
			fallback={
				<div className="flex flex-auto flex-col h-[100vh]">
					<Loading loading={true} />
				</div>
			}
		>
			<AppLayout />
			<Dialog
				isOpen={isCacheOld}
				style={{
					content: {
						marginTop: 250,
					},
				}}
				// contentClassName="pb-0 px-0"
				onClose={() => setIsCacheOld((prev) => !prev)}
				onRequestClose={() => setIsCacheOld((prev) => !prev)}
			>
				<div className="px-2 pb-6">
					<h4 className="mb-4">Update Avaliable!</h4>
					<p className=' text-base'>
						A new version {packageJson.version} of {packageJson.name} is available.
						You are currently using {currentVersion}.
						Please update to the latest version for new features and improvements.
					</p>
				</div>
				<div className="text-right px-6 rounded-bl-lg rounded-br-lg">
					<Button variant="solid"onClick={clearBrowserCache}>
						Update
					</Button>
				</div>
			</Dialog>
		</Suspense>
	)
}

export default memo(Layout)